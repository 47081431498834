// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card.sales-by-state-map .card-contents {
  height: 400px;
}
.card.sales-range .card-contents {
  height: auto;
}

.sales-pie,
.sales-bars {
  display: inline-block;
  height: 100%;
}

.sales-pie {
  width: 400px;
}

.sales-bars {
  width: calc(100% - 600px);
}

.sales-filter {
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .sales-pie,
  .sales-bars {
    display: block;
  }
  .sales-pie {
    width: 320px;
  }
  .sales-bars {
    width: 320px;
  }
}
.cards {
  display: grid;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
  grid-template-columns: repeat(2, calc(50% - 10px));
}
.cards.wide {
  margin-right: 0;
  grid-template-columns: repeat(1, 100%);
}

@media only screen and (max-width: 900px) {
  .cards {
    grid-template-columns: repeat(1, 100%);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/analytics-sales-report/analytics-sales-report.scss"],"names":[],"mappings":"AAEI;EACE,aAAA;AADN;AAMI;EACE,YAAA;AAJN;;AASA;;EAEE,qBAAA;EACA,YAAA;AANF;;AASA;EACE,YAAA;AANF;;AASA;EACE,yBAAA;AANF;;AASA;EACE,qBAAA;AANF;;AASA;EACE;;IAEE,cAAA;EANF;EASA;IACE,YAAA;EAPF;EAUA;IACE,YAAA;EARF;AACF;AAWA;EACE,aAAA;EACA,WAAA;EACA,gBAAA;EACA,SAAA;EACA,kDAAA;AATF;AAWE;EACE,eAAA;EACA,sCAAA;AATJ;;AAaA;EACE;IACE,sCAAA;EAVF;AACF","sourcesContent":[".card {\n  &.sales-by-state-map {\n    .card-contents {\n      height: 400px;\n    }\n  }\n\n  &.sales-range {\n    .card-contents {\n      height: auto;\n    }\n  }\n}\n\n.sales-pie,\n.sales-bars {\n  display: inline-block;\n  height: 100%;\n}\n\n.sales-pie {\n  width: 400px;\n}\n\n.sales-bars {\n  width: calc(100% - 600px);\n}\n\n.sales-filter {\n  display: inline-block;\n}\n\n@media only screen and (max-width: 600px) {\n  .sales-pie,\n  .sales-bars {\n    display: block;\n  }\n\n  .sales-pie {\n    width: 320px;\n  }\n\n  .sales-bars {\n    width: 320px;\n  }\n}\n\n.cards {\n  display: grid;\n  width: 100%;\n  margin-top: 20px;\n  gap: 20px;\n  grid-template-columns: repeat(2, calc(50% - 10px));\n\n  &.wide {\n    margin-right: 0;\n    grid-template-columns: repeat(1, 100%);\n  }\n}\n\n@media only screen and (max-width: 900px) {\n  .cards {\n    grid-template-columns: repeat(1, 100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
