// import React from 'react';
// import { DropDownButton } from 'devextreme-react/drop-down-button';

// import './CardMenu.scss';

// export const CardMenu = ({ items, visible = true }) => {
//   return (
//     <DropDownButton
//       className='overflow-menu'
//       items={items}
//       visible={visible}
//       icon='overflow'
//       stylingMode='text'
//       showArrowIcon={false}
//       dropDownOptions={{ width: 'auto' }}
//     />
//   );
// };
import React from 'react';
import { DropDownButton } from 'devextreme-react/drop-down-button';
import './CardMenu.scss';

interface CardMenuProps {
  items: any[]; // You can replace 'any[]' with the specific type of your items if you know it
  visible?: boolean;
}

export const CardMenu: React.FC<CardMenuProps> = ({ items, visible = true }) => {
  return (
    <DropDownButton
      className='overflow-menu'
      items={items}
      visible={visible}
      icon='overflow'
      stylingMode='text'
      showArrowIcon={false}
      dropDownOptions={{ width: 'auto' }}
    />
  );
};
