// import React from 'react';

// import DateBox from 'devextreme-react/date-box';
// import Validator, { RequiredRule } from 'devextreme-react/validator';

// export const FormDateBox = ({
//   value,
//   readOnly,
//   name,
//   label,
//   onValueChange
// }) => {
//   return (
//     <DateBox
//       value={value}
//       readOnly={readOnly}
//       name={name}
//       label={label}
//       inputAttr={{ class: 'form-editor-input' }}
//       stylingMode='filled'
//       placeholder='MM/dd/y'
//       displayFormat='MM/dd/y'
//       onValueChange={onValueChange}
//     >
//       <Validator>
//         <RequiredRule />
//       </Validator>
//     </DateBox>
//   );
// };
import React from 'react';
import DateBox from 'devextreme-react/date-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';

// Define the types for the props
interface FormDateBoxProps {
  value?: Date; // Adjust this type if needed
  readOnly?: boolean;
  name?: string;
  label?: string;
  onValueChange?: (value: string | number | Date) => void; // Updated type to match DateBox expectations
}

export const FormDateBox: React.FC<FormDateBoxProps> = ({
  value,
  readOnly,
  name,
  label,
  onValueChange
}) => {
  return (
    <DateBox
      value={value}
      readOnly={readOnly}
      name={name}
      label={label}
      inputAttr={{ class: 'form-editor-input' }}
      stylingMode='filled'
      placeholder='MM/dd/y'
      displayFormat='MM/dd/y'
      onValueChange={(value) => {
        // Ensure the onValueChange prop is called with the correct type
        if (onValueChange) {
          onValueChange(value);
        }
      }}
    >
      <Validator>
        <RequiredRule />
      </Validator>
    </DateBox>
  );
};
