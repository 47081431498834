import { BASE_URL } from '../utils/default-user';

interface ApiResponse {
  status: string;
  token?: string;
  message?: string;
  userInfo?: any;
}

interface Result {
  isOk: boolean;
  data?: any;
  message?: string;
}

export async function signIn(username: string, password: string): Promise<Result> {
  try {
    const loginEndpoint = `${BASE_URL}login`;
    const response = await fetch(loginEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const data: ApiResponse = await response.json();

    if (data.status === 'success') {
      if (data.token) {
        localStorage.setItem('token', data.token); // Save token in localStorage
      }
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.message || 'Invalid username or password',
      };
    }
  } catch (error) {
    console.error('Error:', error);
    return {
      isOk: false,
      message: 'Authentication failed',
    };
  }
}

export async function createAccount(email: string, phone: string, username: string, password: string): Promise<Result> {
  try {
    const registerEndpoint = `${BASE_URL}register`;
    const registrationData = { email, phone, username, password };

    const response = await makeApiRequest(registerEndpoint, 'POST', registrationData);

    if (response.status === 'success' && response.message === 'User registered successfully') {
      return {
        isOk: true,
      };
    } else {
      return {
        isOk: false,
        message: response.message || 'Failed to create account',
      };
    }
  } catch (error) {
    console.error('Error:', error);
    return {
      isOk: false,
      message: 'Failed to create account',
    };
  }
}

async function makeApiRequest(url: string, method: string, data: any): Promise<ApiResponse> {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return await response.json();
  } catch (error) {
    console.error('Error:', error);
    throw new Error('API request failed');
  }
}

export async function changePassword(email: string, recoveryCode: string): Promise<Result> {
  try {
    const changePasswordEndpoint = `${BASE_URL}change-password`;
    const response = await fetch(changePasswordEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, recoveryCode }),
    });

    const data: ApiResponse = await response.json();

    if (data.status === 'success') {
      return {
        isOk: true,
      };
    } else {
      return {
        isOk: false,
        message: data.message || 'Failed to change password',
      };
    }
  } catch (error) {
    console.error('Error:', error);
    return {
      isOk: false,
      message: 'Failed to change password',
    };
  }
}

export async function resetPassword(email: string): Promise<Result> {
  try {
    const resetPasswordEndpoint = `${BASE_URL}reset-password`;
    const response = await fetch(resetPasswordEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data: ApiResponse = await response.json();

    if (data.status === 'success') {
      return {
        isOk: true,
      };
    } else {
      return {
        isOk: false,
        message: data.message || 'Failed to reset password',
      };
    }
  } catch (error) {
    console.error('Error:', error);
    return {
      isOk: false,
      message: 'Failed to reset password',
    };
  }
}

export async function getUser(): Promise<Result> {
  try {
    const getUserEndpoint = `${BASE_URL}get-user`;
    const response = await fetch(getUserEndpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const data: ApiResponse = await response.json();

    if (response.ok) {
      return {
        isOk: true,
        data: data.userInfo,
      };
    } else {
      return {
        isOk: false,
        message: data.message || 'Failed to fetch user info',
      };
    }
  } catch (error) {
    console.error('Error:', error);
    return {
      isOk: false,
      message: 'Failed to fetch user info',
    };
  }
}
