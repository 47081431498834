// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-texteditor.form-editor [role=button].dx-button.form-editor-icon:first-child {
  margin-left: 0;
  pointer-events: none;
  border-color: transparent;
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/utils/form-textbox/FormTextbox.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,oBAAA;EACA,yBAAA;EACA,6BAAA;AACF","sourcesContent":[".dx-texteditor.form-editor [role=\"button\"].dx-button.form-editor-icon:first-child {\n  margin-left: 0;\n  pointer-events: none;\n  border-color: transparent;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
