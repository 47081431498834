// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#uploader {
  background-color: var(--typography-bg);
  border-width: 1.5px;
  border-style: dashed;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 124px;
  -webkit-user-select: none;
          user-select: none;
  line-height: inherit;
  margin-bottom: 10px;
}
#uploader > span {
  font-weight: 100;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/utils/form-photo-uploader/FormPhotoUploader.scss"],"names":[],"mappings":"AAAA;EACE,sCAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;UAAA,iBAAA;EACA,oBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,kBAAA;AACJ","sourcesContent":["#uploader {\n  background-color: var(--typography-bg);\n  border-width: 1.5px;\n  border-style: dashed;\n  border-radius: 8px;\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 124px;\n  user-select: none;\n  line-height: inherit;\n  margin-bottom: 10px;\n\n  & > span {\n    font-weight: 100;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
