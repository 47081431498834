import { useState, useEffect } from 'react';
interface NavigationItem {
  text: string;
  path: string;
  icon?: string;
  items?: NavigationItem[];
}

interface FetchedDataItem {
  label: string;
  name: string;
  icon?: string;
  items?: FetchedDataItem[];
}

interface FetchedData {
  data: FetchedDataItem[];
}

export const useNavigationData = () => {
  const [navigation, setNavigation] = useState<NavigationItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = 'https://med-b.ealamgroup.pk/record/apptab';
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          if (response.status === 403) {
            throw new Error("Permission denied. You don't have access.");
          } else {
            throw new Error(`Permission denied. You don't have access: ${response.statusText}`);
          }
        }

        const data: FetchedData = await response.json();
        const mappedData: NavigationItem = {
          text: 'Health',  // Static name
          icon: 'like',  // Reference the custom icon CSS class
          path: '',
          items: data.data.map((item: FetchedDataItem) => ({
            text: item.label,
            path: `/task-list?tab=${item.name}`,
            icon: item.icon || '',
          })),
        };

        const staticItems: NavigationItem[] = [
          {
            text: 'Static Analytics',
            icon: 'chart',
            path: '',
            items: [
              {
                text: 'Dashboard',
                path: '/analytics-dashboard',
              },
              {
                text: 'Sales Report',
                path: '/analytics-sales-report',
              },
              {
                text: 'Geography',
                path: '/analytics-geography',
              },
            ],
          },
          {
            text: 'Static CRM',
            icon: 'user',
            path: '',
            items: [
              {
                text: 'Contact List',
                path: '/crm-contact-list',
              },
              {
                text: 'Contact Details',
                path: '/crm-contact-details',
              },
            ],
          },
          {
            text: 'Static Planning',
            icon: 'event',
            path: '',
            items: [
              {
                text: 'Task List',
                path: '/planning-task-list',
              },
              {
                text: 'Task Details',
                path: '/planning-task-details',
              },
            ],
          },
        ];

        setNavigation([...staticItems, mappedData]);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError((error as Error).message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { navigation, loading, error };
};
