// import { currentTheme as currentVizTheme, refreshTheme } from 'devextreme/viz/themes';
// import { current as getCurrentDXTheme } from 'devextreme/ui/themes';
// import React, { useCallback, useEffect, useMemo, useState } from 'react';

// const themes = ['light', 'dark'] as const;
// const storageKey = 'app-theme';
// const themePrefix = 'app-theme-';

// const prefixes = ['./styles/theme-dx-', './styles/variables-'];

// const loadStylesImports = async() => {
//   await Promise.all([
//     ...prefixes.flatMap((prefix) => [
//       import(/* webpackChunkName: "app-theme-dark" */ `${prefix}dark.scss`),
//       import(/* webpackChunkName: "app-theme-light" */ `${prefix}light.scss`)
//     ]),
//   ]);
// };

// export type Theme = typeof themes[number];

// function getNextTheme(theme?: Theme) {
//   return themes[themes.indexOf(theme as Theme) + 1] || themes[0];
// }

// function getCurrentTheme(): Theme {
//   return window.localStorage[storageKey] || getNextTheme();
// }

// function isThemeStyleSheet(styleSheet: CSSStyleSheet, theme: Theme) {
//   const themeMarker = `${themePrefix}${theme}`;
//   // eslint-disable-next-line no-undef
//   if(process.env.NODE_ENV === 'production') {
//     return styleSheet?.href?.includes(`${themeMarker}`);
//   } else {
//     const rules = Array.from<CSSStyleRule>(styleSheet.cssRules);
//     return !!rules.find((rule) => rule?.selectorText?.includes(`.${themeMarker}`));
//   }
// }

// function switchThemeStyleSheets(enabledTheme: Theme) {
//   const disabledTheme = getNextTheme(enabledTheme);

//   Array.from<CSSStyleSheet>(document.styleSheets).forEach((styleSheet) => {
//     styleSheet.disabled = isThemeStyleSheet(styleSheet, disabledTheme);
//   });
// }

// async function setAppTheme(newTheme?: Theme) {
//   const themeName = newTheme || getCurrentTheme();

//   switchThemeStyleSheets(themeName);

//   const regexTheme = new RegExp(`\\.(${themes.join('|')})`, 'g');
//   currentVizTheme(currentVizTheme().replace(regexTheme, `.${themeName}`));
//   refreshTheme();
// }

// function toggleTheme(currentTheme: Theme): Theme {
//   const newTheme = getNextTheme(currentTheme);
//   window.localStorage[storageKey] = newTheme;
//   return newTheme;
// }

// export function useThemeContext() {
//   const [theme, setTheme] = useState(getCurrentTheme());
//   const [isLoaded, setIsLoaded] = useState(false);

//   useEffect(() => {
//     loadStylesImports().then(() => {
//       setIsLoaded(true);
//     });
//   }, []);

//   const switchTheme = useCallback(() => setTheme((currentTheme: Theme) => toggleTheme(currentTheme)), []);

//   const isFluent = useCallback((): boolean => {
//     return getCurrentDXTheme().includes('fluent');
//   }, []);

//   useEffect(() => {
//     isLoaded && setAppTheme(theme);
//   }, [theme, isLoaded]);

//   return useMemo(()=> ({ theme, switchTheme, isLoaded, isFluent }), [theme, isLoaded, isFluent]);
// }

// export const ThemeContext = React.createContext<ReturnType<typeof useThemeContext> | null>(null);
import { currentTheme as currentVizTheme, refreshTheme } from 'devextreme/viz/themes';
import { current as getCurrentDXTheme } from 'devextreme/ui/themes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const themes = ['light', 'dark'] as const;
const storageKey = 'app-theme';
const themePrefix = 'app-theme-';

const prefixes = ['./styles/theme-dx-', './styles/variables-'];

const loadStylesImports = async() => {
  await Promise.all([
    ...prefixes.flatMap((prefix) => [
      import(/* webpackChunkName: "app-theme-dark" */ `${prefix}dark.scss`),
      import(/* webpackChunkName: "app-theme-light" */ `${prefix}light.scss`)
    ]),
  ]);
};

export type Theme = typeof themes[number];

function getNextTheme(theme?: Theme): Theme {
  return themes[themes.indexOf(theme as Theme) + 1] || themes[0];
}

function getCurrentTheme(): Theme {
  return window.localStorage[storageKey] || getNextTheme();
}

function isThemeStyleSheet(styleSheet: CSSStyleSheet, theme: Theme): boolean {
  const themeMarker = `${themePrefix}${theme}`;
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'production') {
    return styleSheet?.href?.includes(`${themeMarker}`) ?? false;
  } else {
    const rules = Array.from(styleSheet.cssRules) as CSSStyleRule[];
    return !!rules.find((rule) => rule.selectorText?.includes(`.${themeMarker}`));
  }
}

function switchThemeStyleSheets(enabledTheme: Theme) {
  const disabledTheme = getNextTheme(enabledTheme);

  Array.from(document.styleSheets).forEach((styleSheet) => {
    if (styleSheet instanceof CSSStyleSheet) {
      styleSheet.disabled = isThemeStyleSheet(styleSheet, disabledTheme);
    }
  });
}

async function setAppTheme(newTheme?: Theme) {
  const themeName = newTheme || getCurrentTheme();

  switchThemeStyleSheets(themeName);

  const regexTheme = new RegExp(`\\.(${themes.join('|')})`, 'g');
  currentVizTheme(currentVizTheme().replace(regexTheme, `.${themeName}`));
  refreshTheme();
}

function toggleTheme(currentTheme: Theme): Theme {
  const newTheme = getNextTheme(currentTheme);
  window.localStorage[storageKey] = newTheme;
  return newTheme;
}

export function useThemeContext() {
  const [theme, setTheme] = useState(getCurrentTheme());
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    loadStylesImports().then(() => {
      setIsLoaded(true);
    });
  }, []);

  const switchTheme = useCallback(() => setTheme((currentTheme: Theme) => toggleTheme(currentTheme)), []);

  const isFluent = useCallback((): boolean => {
    return getCurrentDXTheme().includes('fluent');
  }, []);

  useEffect(() => {
    isLoaded && setAppTheme(theme);
  }, [theme, isLoaded]);

  return useMemo(() => ({ theme, switchTheme, isLoaded, isFluent }), [theme, isLoaded, isFluent]);
}

export const ThemeContext = React.createContext<ReturnType<typeof useThemeContext> | null>(null);
